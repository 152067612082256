
  import { defineComponent, reactive, toRefs, onBeforeMount } from 'vue';
  import { addUser } from '@/api/userinfo';
  import { getAllOrganization } from '@/api/organization';
  import { useRouter, useRoute } from 'vue-router';
  import { ElMessage } from 'element-plus';
  import { getALlSystem } from '@/api/system';
  import { validatePhone } from '../../utils/validate';

  export default defineComponent({
    name: 'userManage',
    props: {
      drawer: Boolean,
    },
    setup(props, { emit }) {
      console.log(props);
      const route = useRoute();
      const router = useRouter();
      const state = reactive({
        addForm: {
          userName: '',
          organizationIds: [],
          password: '',
          idNo: '',
          jobNo: '',
          phoneNum: '',
          root: false,
          systemList: [],
        },
        rules: {
          userName: [
            {
              required: true,
              message: '请输入用户姓名',
              trigger: 'blur',
            },
          ],
          organizationIds: [
            {
              required: true,
              message: '请选择组织',
              trigger: 'change',
            },
          ],
          password: [
            {
              required: true,
              message: '请输入密码',
              trigger: 'blur',
            },
          ],
          jobNo: [
            {
              // required: true,
              message: '请输入工号',
              trigger: 'blur',
            },
          ],
          phoneNum: [
            {
              required: true,
              validator: validatePhone,
              trigger: 'blur',
            },
          ],
          systemList: [
            {
              required: true,
              message: 'Please select Activity zone',
              trigger: 'change',
            },
          ],
        },
        ruleForm: null,
        organizationData: [],
        systemData: [],
      });
      const handleSubmit = () => {
        const form: any = state.ruleForm;
        form.validate(async (valid: any) => {
          if (valid) {
            const params = { ...state.addForm };
            const res: any = await addUser(params);
            if (res.code === 0) {
              ElMessage({
                message: '新建成功',
                type: 'success',
              });

              router.push('/userManage');
            } else {
              ElMessage({
                message: res.msg,
                type: 'warning',
              });
            }
          }
        });
      };
      const resetForm = () => {
        const form: any = state.ruleForm;
        form.resetFields();
      };
      const handleback = () => {
        router.push('/userManage');
      };

      const getOrganizationData = async () => {
        const res = await getAllOrganization();
        state.organizationData = res.data;
      };

      const getSystemDate = async (params: any) => {
        const res = await getALlSystem(params);
        state.systemData = res.data;
      };

      onBeforeMount(() => {
        getOrganizationData();
        const params = {
          name: '',
        };
        getSystemDate(params);
      });

      return {
        ...toRefs(state),
        handleSubmit,
        resetForm,
        handleback,
        getOrganizationData,
        props,
      };
    },
  });
