import $http from '@/utils/axios/axiosUtils';

export function getAllOrganization() {
  return $http({
    url: `/org/list`,
    method: 'get',
  });
}

export function getGraph() {
  return $http({
    url: `/org/graph/show`,
    method: 'get',
  });
}

export function saveGraph(params: any) {
  return $http({
    url: `/org/graph/draw`,
    method: 'post',
    data: params,
  });
}

export function addGraph(params: any) {
  return $http({
    url: `/org`,
    method: 'post',
    data: params,
  });
}
