// 验证密码
export function validatePwd(pwdText: string) {
  return /^([A-Z]|[a-z]|[0-9]){6,16}$/.test(pwdText);
}

// -------- element-ui validate -----------
/**
 * 验证手机号
 * @param rule
 * @param value
 * @param callback
 * @returns
 */
export function validatePhone(rule: any, value: string, callback: Function) {
  let errMsg = '';
  if (!value) {
    errMsg = '手机号不能为空';
  } else {
    const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
    if (reg.test(value)) {
      callback();
    } else {
      errMsg = '请输入正确的手机号';
    }
  }
  return callback(new Error(errMsg));
}

/**
 * 验证身份证号
 * @param rule
 * @param value
 * @param callback
 * @returns
 */
export function validateIdNo(rule: any, value: string, callback: Function) {
  let errMsg = '';
  if (!value) {
    errMsg = ' 身份证号不能为空';
  } else {
    const reg = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/;
    if (reg.test(value)) {
      callback();
    } else {
      errMsg = '请输入有效大陆身份证号';
    }
  }
  return callback(new Error(errMsg));
}

/**
 * 验证密码
 * @param rule
 * @param value
 * @param callback
 */
export function validatePassword(rule: any, value: string, callback: Function) {
  if (!validatePwd(value)) {
    callback(new Error('密码必须由字母，数字组成'));
  } else {
    callback();
  }
}

/**
 * 验证密码
 * @param rule
 * @param value
 * @param callback
 */
export function validateChangePassword(rule: any, value: string, callback: Function) {
  if (!value) {
    callback(new Error('请输入密码'));
  } else if (value?.length > 16 || value?.length < 6) {
    callback(new Error('密码长度位6~16位'));
  } else if (!validatePwd(value)) {
    callback(new Error('密码必须由字母，数字组成'));
  } else {
    callback();
  }
}
